<template>
  <app-layout>
    <div class="px-4 px-sm-5 container">
      <async-object
        should-fetch
        :fetch-method="fetchData"
      >
        <div class="heading d-flex justify-content-between mb-5">
          <h2>Commande {{ reference }}</h2>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="mb-4">
              <router-link class="btn btn-link btn-sm" to="/orders" active-class="active">
                <fa-icon :icon="['fas', 'chevron-left']" size="sm"></fa-icon>&nbsp;Retour
              </router-link>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="mb-4">
              <template v-if="order && order.status !== 'completed' && order.status !== 'cancelled'">
                <button class="btn btn-primary btn-sm" @click="createShipment">Créer une expédition</button>
              </template>

              <template v-if="unreadMessages > 0">
                <router-link :to="`/customer-messages/${parentOrderId}`" class="btn btn-outline-primary btn-sm ml-2">{{ unreadMessages }} message(s) non lu(s)</router-link>
              </template>
              <template v-else-if="hasMessages">
                <router-link :to="`/customer-messages/${parentOrderId}`" class="btn btn-outline-primary btn-sm ml-2">Voir les messages</router-link>
              </template>
              <template v-else>
                <button class="btn btn-outline-primary btn-sm ml-2" @click="contactCustomer">Contacter le client</button>
              </template>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <div class="card mb-4">
              <div class="card-body">
                <h4 class="header-title mb-4">Sommaire</h4>
                <dl class="row">
                  <dt class="col-4">Date de commande</dt>
                  <dd class="col-8">{{ $shortDateTime(orderDate) }}</dd>
                </dl>
                <dl class="row">
                  <dt class="col-4">Sous-total</dt>
                  <dd class="col-8">{{ $money(subTotal) }}</dd>
                </dl>
                <dl class="row">
                  <dt class="col-4">Taxes</dt>
                  <dd class="col-8">{{ $money(totalTaxes) }}</dd>
                </dl>
                <dl class="row">
                  <dt class="col-4">Statut</dt>
                  <dd class="col-8">{{ orderStatus }}</dd>
                </dl>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="card mb-4">
              <div class="card-body">
                <h4 class="header-title mb-4">Adresse de livraison</h4>
                <p class="address">
                  {{ shippingAddress.name }}
                  <template v-if="shippingAddress.company">
                    <br> {{ shippingAddress.company }}
                  </template>
                  <br>{{ shippingAddress.address }}
                  <template v-if="shippingAddress.address2">
                    <br> {{ shippingAddress.address2 }}
                  </template>
                  <br>{{ shippingAddress.city }}, {{ shippingAddress.province }}, {{ shippingAddress.postalCode }}
                  <br>Canada
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="card mb-4">
              <div class="card-body p-0">
                <h4 class="header-title with-padding">Articles</h4>

                <div class="table-responsive">
                  <table class="table nowrap-table">
                    <thead>
                    <th>Article</th>
                    <th class="col-100">Quantité</th>
                    <th class="col-100">Prix</th>
                    <th class="col-100">Sous-total</th>
                    <th class="col-100">Taxes</th>
                    <th class="col-100">Total</th>
                    <th>Statut</th>
                    </thead>
                    <tbody>
                    <template v-for="item in orderItems">
                      <order-item-row :item="item" :key="item.id"></order-item-row>
                    </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </async-object>
    </div>
  </app-layout>
</template>

<script>
import { get } from 'lodash-es'
import userData from '@/mixins/user-data'
import orderStatus from '@/mixins/orderStatus'
import formatsCurrency from '@/mixins/formatsCurrency'
import formatsDate from '@/mixins/formatsDate'
import OrderItemRow from '@/components/orders/OrderItemRow'
import http from '@/utils/http'

export default {
  mixins: [userData, orderStatus, formatsCurrency, formatsDate],
  components: {
    OrderItemRow
  },
  data () {
    return {
      parentOrderId: null,
      messages: []
    }
  },
  computed: {
    order () {
      return this.$store.getters['orders/getDetailsById'](this.$route.params.id)
    },
    orderItems () {
      return this.order ? this.order.items : []
    },
    reference () {
      return get(this.order, 'order.reference')
    },
    orderDate () {
      return get(this.order, 'order.createdAt')
    },
    subTotal () {
      return get(this.order, 'subtotal')
    },
    totalTaxes () {
      const taxes = get(this.order, 'taxes', {})
      let total = 0

      for (const tax in taxes) {
        total += Number(taxes[tax])
      }

      return total
    },
    orderStatus () {
      const status = get(this.order, 'status')
      return this.getOrderStatusText(status)
    },
    shippingAddress () {
      return get(this.order, 'shippingAddress', {})
    },
    hasMessages () {
      return Array.isArray(this.messages) && this.messages.length > 0
    },
    unreadMessages () {
      if (this.hasMessages) {
        const unreadItems = this.messages.filter(message => message.isUnreadByMerchant)
        return unreadItems.length
      }
      return 0
    }
  },
  methods: {
    createShipment () {
      this.$router.push({
        name: 'shipments.create',
        params: {
          orderId: this.order.id
        }
      })
    },
    async fetchData () {
      const order = await this.$store.dispatch('orders/fetchById', {
        id: this.$route.params.id,
        storeId: this.currentStoreId
      })

      this.parentOrderId = order && order.order ? order.order.id : null
    },
    async fetchMessages (orderId) {
      const messages = await this.$store.dispatch('customerMessages/history/fetchOrRefresh', {
        parentId: orderId,
        storeId: this.currentStoreId
      })

      this.messages = messages
    },
    async contactCustomer () {
      try {
        await this.$modal.openFormModal({
          title: 'Contacter le client',
          fields: [
            {
              name: 'message',
              label: 'Votre message',
              type: 'textarea',
              placeholder: 'Rédigez votre message...'
            }
          ],
          confirmLabel: 'Envoyer',
          cancelLabel: 'Annuler',
          disableEnterKey: true,
          onSubmit: async ({ values, confirm, setErrors }) => {
            setErrors({})

            if (!values.message) {
              setErrors({
                message: ['Ce champ est requis.']
              })
              return
            }

            await http.post('/v1/customer-messages', {
              text: values.message,
              storeId: this.currentStoreId,
              orderId: this.parentOrderId
            })

            confirm()

            this.$toasted.success('Votre message a bien été envoyé!')
            this.fetchMessages(this.parentOrderId)
          }
        })
      } catch (error) {
        if (error === 'ACTION_CANCELLED') {
          return
        }

        this.$toasted.error('Une erreur s\'est produite. Veuillez réessayer.')
      }
    }
  },
  watch: {
    parentOrderId (value, oldValue) {
      if (value !== oldValue) {
        this.fetchMessages(value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.address {
  line-height: 24px;
}
</style>
